import { List, Card } from "antd";

export default function Hobbies() {
  return (
    <div className="cardContainer" id="hobbiesContainer">
      <Card title="Hobbies" style={{ margin: 10 }}>
        <div className="hobbiesContent">
          <List
            dataSource={[
              "Gaming",
              "fitness",
              "programming",
              "dj'ing",
              "music production",
            ]}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </div>
      </Card>
    </div>
  );
}
