import { Card, Timeline } from "antd";

export default function Education() {
  return (
    <div className="cardContainer" id="educationCardContainer">
      <Card title="My education" style={{ margin: 10 }}>
        <div style={{ width: "40%", textAlign: "left", marginLeft: "10px" }}>
          <Timeline
            style={{ padding: 0 }}
            items={[
              {
                children:
                  "2015-2021: Commercial education @ Sint-Francisus Evergem",
              },
              {
                children:
                  "2021-current: Applied computer science - Business analist @ Hogent",
              },
            ]}
          />
        </div>
      </Card>
    </div>
  );
}
