import { Card, Col, Row, Tag } from "antd";

const { Meta } = Card;

export default function Projects() {
  return (
    <div className="cardContainer" id="projectsContainer">
      <Card title="My projects" style={{ margin: 10 }}>
        <Row gutter={16}>
          <Col>
            <Card
              hoverable
              onClick={() =>
                window.open("https://reservelyreact.onrender.com/")
              }
              style={{ width: 300, padding: 10, height: 330 }}
              cover={<img alt="example" src="https://imgur.com/wYaVRqv.png" />}
            >
              <Meta
                title="Reservely"
                description="A reservation app for hotels, vacation homes... The frontend was built with react, backend with nodejs and koajs."
              />
              <br />
              <Tag color="blue">React</Tag>
              <Tag color="green">Node.js</Tag>
              <Tag color="purple">Koa.js</Tag>
            </Card>
          </Col>
          <Col>
            <Card
              hoverable
              onClick={() =>
                window.open(
                  "https://github.com/jamievschuerbeek/iosdev-budgetapp-jamievs"
                )
              }
              style={{ width: 300, padding: 10, height: 330 }}
              cover={
                <img
                  style={{ height: 96, width: 280, objectFit: "cover" }}
                  alt="example"
                  src="https://developer.apple.com/news/images/og/swiftui-og.png"
                />
              }
            >
              <Meta
                title="Budget app"
                description="An ios app to get an overview of your income and expenses. The app was built using swiftui and the api using vapor."
              />
              <br />
              <Tag color="volcano">Swift</Tag>
              <Tag color="blue">Swiftui</Tag>
              <Tag color="magenta">Vapor</Tag>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
