import "./App.css";
import AboutMe from "./components/home/AboutMe";
import Education from "./components/home/Education";
import Hobbies from "./components/home/Hobbies";
import Languages from "./components/home/Languages";
import Projects from "./components/home/Projects";

function App() {
  return (
    <div className="App">
      <section className="intro">
        <div className="intro_content">
          <h1 className="introText">
            <span className="introName">Jamie Van Schuerbeek</span> <br />
            Applied Computer Science Student @Hogent
          </h1>
          <span onClick={scrollDown} className="scroll-down"></span>
        </div>
      </section>
      <AboutMe />
      <Languages />
      <Education />
      <Hobbies />
      <Projects />
    </div>
  );
}

function scrollDown() {
  document.getElementById("aboutMeContainer").scrollIntoView({
    behavior: "smooth",
  });
}

export default App;
