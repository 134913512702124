import { Card, Progress } from "antd";
export default function Languages() {
  return (
    <div className="cardContainer" id="languagesContainer">
      <Card title="Languages" style={{ margin: 10 }}>
        <div className="languagesContent">
          <div className="language">
            <p>Dutch</p>
            <Progress percent={95} />
          </div>
          <div className="language">
            <p>English</p>
            <Progress percent={80} />
          </div>
          <div className="language">
            <p>French</p>
            <Progress percent={40} />
          </div>
          <div className="language">
            <p>German</p>
            <Progress percent={15} />
          </div>
        </div>
      </Card>
    </div>
  );
}
