import { Card, Progress } from "antd";
import {
  InstagramOutlined,
  LinkedinOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

function AboutMe() {
  return (
    <>
      <div className="cardContainer" id="aboutMeContainer">
        <Card title="About me" style={{ margin: 10 }}>
          <div className="aboutMeContent">
            <img src="/me.jpg" alt="me" width={150} height={150} />
            <p>
              Hi! My name is <strong>Jamie Van Schuerbeek</strong> I'm an
              applied computer science student from Belgium studying ERP
              development <a href="https://hogent.be">@Hogent</a>.
            </p>
            <a href="https://www.instagram.com/jamie_van_schuerbeek/">
              <InstagramOutlined style={{ fontSize: "200%" }} />{" "}
            </a>
            <a href="https://www.linkedin.com/in/jamievs/">
              <LinkedinOutlined style={{ fontSize: "200%" }} />
            </a>
            <a href="https://www.youtube.com/@jamievs">
              <YoutubeOutlined style={{ fontSize: "200%" }} />
            </a>
          </div>
        </Card>
      </div>
      <Skills />
    </>
  );
}

function Skills() {
  return (
    <div className="skillsContainer cardContainer">
      <Card title="Skills" style={{ margin: 10 }}>
        <div className="skillsContent">
          <div className="skill">
            <p>HTML</p>
            <Progress percent={80} />
          </div>
          <div className="skill">
            <p>React</p>
            <Progress percent={75} />
          </div>
          <div className="skill">
            <p>CSS</p>
            <Progress percent={70} />
          </div>
          <div className="skill">
            <p>Javascript</p>
            <Progress percent={60} />
          </div>
          <div className="skill">
            <p>Java</p>
            <Progress percent={75} />
          </div>
        </div>
        <div className="skill">
          <p>Python</p>
          <Progress percent={50} />
        </div>
      </Card>
    </div>
  );
}

export default AboutMe;
